import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {getUser} from './assets/js/get-user'
import { globalMixin } from './common/globalMixin' // Import the mixin
import vue3GoogleLogin from 'vue3-google-login'
import 'vuetify/styles'; // Import the Vuetify styles
import { createVuetify } from 'vuetify';
import '@mdi/font/css/materialdesignicons.css'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import store from './store/index.js';
// import the package

import VueAwesomePaginate from "vue-awesome-paginate";

// import the necessary css file

import "vue-awesome-paginate/dist/style.css";
const API_URL = process.env.VUE_APP_API_URL

const vuetify = createVuetify({
  components,
  directives,
});
getUser().then(()=> {
    const app = createApp(App)

    // Globally register the mixin
    app.mixin(globalMixin)

    app.use(vue3GoogleLogin, {
      clientId: '244214142847-n1pq1f2kk17nhf3hh4p72td6l8oasur4.apps.googleusercontent.com'
    })
    app.use(VueAwesomePaginate)
    app.use(vuetify);
    app.use(store);
    app.use(router).mount('#app')
    window.main_url = `${API_URL}/users`
})

