<template>
    <main class="orders_wrapper">
      <div class="page-head">
        <div class="container">
          <router-link to="/">{{ lang == 'en' ? 'Home' : 'الرئيسية' }}</router-link>
          <i :class="lang == 'en' ? 'fa-solid fa-chevron-right' : 'fa-solid fa-chevron-left'"></i>
          {{ lang == 'en' ? 'Account' : 'الحساب' }}
          <i :class="lang == 'en' ? 'fa-solid fa-chevron-right' : 'fa-solid fa-chevron-left'"></i>
          <router-link to="/my-orders">{{ lang == 'en' ? 'Orders' : 'عمليات الشراء' }}</router-link>
          <i :class="lang == 'en' ? 'fa-solid fa-chevron-right' : 'fa-solid fa-chevron-left'"></i>
          {{ lang == 'en' ? 'Tracking Details' : 'تفاصيل التتبع' }}
        </div>
      </div>
  
      <div class="container">
        <div class="tracking-container">
          <!-- Shipment Info -->
          <div class="shipment-info">
            <div class="shipment-no left">
              <p>{{ lang == 'en' ? 'Shipment Number' : 'رقم التتبع:' }}</p>
              <div>
                <span>{{ trackingData[0].Key }}</span> <i class="fa-solid fa-truck"></i>
              </div>
            </div>
            <div class="latest-update left">
                {{ lang == 'en' ? 'Latest Update' : 'التحديث الاخير:' }}
              <p class="lup-one"> 
                </p>
              <p class="lup-two"> {{ lang == 'en' ? 'Shipment update:' : 'تحديث الشحنة :' }}<span>{{ latestUpdate }}</span></p>
              <p class="lup-three">{{ latestDate }}</p>
            </div>
            <div class="status">
              <p class="status-delivered">{{ lang == 'en' ? status : getArabicStatus(status) }}</p>
            </div>
          </div>
          <!-- Origin & Destination -->
          <div class="location">
            <div class="origin">
              <p> {{ lang == 'en' ? 'Origin:' : 'المنشأ:' }}</p>
              <span>{{ originCountry }}</span>
              <p>{{ originCity }}</p>
            </div>
            <div class="destination">
              <p> {{ lang == 'en' ? 'Destination:' : 'الوجهة:' }}</p>
              <span>{{ destinationCountry }}</span>
              <p>{{ destinationCity }}</p>
            </div>
          </div>
          <!-- Stepper -->
          <v-stepper alt-labels class="tracking-stepper" elevation="0" :model-value="currentStep + 1">
            <v-stepper-header>
              <template v-for="(step, index) in trackingSteps" :key="index">
                <v-stepper-item :title="lang == 'en' ? step : getArabicStatus(step)" :value="index + 1" complete
                  :color="index < currentStep ? 'error' : (index === currentStep ? 'green' : 'grey')">
                </v-stepper-item>
                <v-divider v-if="index < trackingSteps.length - 1" :color="'error'" thickness="5"></v-divider>
              </template>
            </v-stepper-header>
          </v-stepper>
        </div>
      </div>
    </main>
  </template>
  
  <script>
  export default {
    name: "TrackOrderView",
    data() {
      return {
        lang: "en",
        latestUpdate: "COD Transferred",
        latestDate: "31 March 2025 04:17:23PM",
        status: "",
        orderData: [],
        trackingSteps: [
          "Created",
          "Collected",
          "Departed",
          "In transit",
          "Out for delivery",
          "Delivered",
        ],
        currentStep: 0,
        trackingData: [],
        originCity: "",
        originCountry: "",
        destinationCity: "",
        destinationCountry: "",
      };
    },
    methods: {
        getArabicStatus(value){
            if (value=='Created') return 'انشاء الشحنة'
            else if (value=='Collected') return ' استلام الشحنة '
            else if (value=='Departed') return 'مغادرة المنشأ'
            else if (value=='In transit') return 'في الطريق'
            else if (value=='Out for delivery') return 'الخروج للتوصيل'
            else if (value=='Delivered') return 'تم التوصيل'
        },
      showTrackingData() {
        this.trackingData = this.orderData.tracking_data;
        let originLocation = this.trackingData[0].Value[0].UpdateLocation;
        let splits = originLocation.split(",");
        this.originCity = splits[0];
        this.originCountry = splits[1];
        this.destinationCity = this.orderData.details.city;
        this.destinationCountry = this.orderData.details.country;
        let trackingHistory = this.trackingData[0].Value;
        // Define step mappings
        const stepMappings = {
          "Record created.": 0,
          "Received at Origin Facility": 1,
          "Departed Operations facility – In Transit": 2,
          "Under processing at operations facility": 3,
          "Shipment on Hold": 3,
          "Out for Delivery": 4,
          "Delivered": 5
        };
        // Initialize currentStep to the lowest value
        let latestEntry = null;
        this.currentStep = 0;
        trackingHistory.forEach(entry => {
          let step = stepMappings[entry.UpdateDescription] ?? 0;
          if (step > this.currentStep) {
            this.currentStep = step;
            latestEntry = entry;
          }
        });
        this.status = this.trackingSteps[this.currentStep];
        if (latestEntry) {
          this.latestUpdate = latestEntry.UpdateDescription;
          const timestamp = latestEntry.UpdateDateTime.match(/\d+/)[0];
          this.latestDate = new Date(parseInt(timestamp)).toLocaleString();
        }
      },
      setLangCookies() {
        let langCheck = document.cookie.indexOf('lang')
  
        this.is_cookies = langCheck >= 0 ? true : false
  
        function getCookie(cname) {
          let name = cname + "=";
          let decodedCookie = decodeURIComponent(document.cookie);
          let ca = decodedCookie.split(';');
          for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
              c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
              return c.substring(name.length, c.length);
            }
          }
          return "";
        } // to get an cookie by name ##############################
  
        if (langCheck !== -1) {
          this.lang = getCookie('lang') // check lang cookie exist ##############################
        }
  
        if (sessionStorage.getItem("lang"))
          this.lang = sessionStorage.getItem("lang") // check lang session exist ##############################
  
        sessionStorage.setItem("lang", this.lang); // set lang session ##############################
  
        let searchParams = new URLSearchParams(window.location.search)
        if (searchParams.has('lang')) {
          this.lang = searchParams.get('lang')
          document.body.classList.add(searchParams.get('lang')) // add lang class ##############################
        } else {
          document.body.classList.add(this.lang) // add lang class ##############################
        }
  
      },
      getTrackOrderData() {
        this.setLangCookies()
        if (this.$route.query.tracking) {
          this.orderData = JSON.parse(this.$route.query.tracking);
          this.showTrackingData();
        }
      },
    },
    created() {
      this.getTrackOrderData()
    },
  
  };
  </script>
  
  <style scoped>
  /* .container {
    width: 100%;
  } */
  .tracking-container {
    max-width: 100%;
    width: 100%;
    margin: auto;
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .shipment-info {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 15px;
  }
  
  .left {
    text-align: left;
  }
  
  .shipment-no {
    justify-content: center;
  }
  
  .shipment-no p {
    color: red;
  }
  
  .shipment-no div span {
    font-weight: 600;
    font-size: 22px;
  }
  
  .lup-one {
    font-weight: 600;
  }
  
  .lup-two {
    font-size: 15px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.7);
  }
  
  .lup-three {
    font-size: 14px;
    font-weight: 400;
    color: grey;
  }
  
  .status-delivered {
    justify-content: center;
    /* margin-top: 5px;
    margin-bottom: 5px; */
    background: powderblue;
    color: black;
    font-weight: 800;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .location {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .origin {
    text-align: left;
  }
  
  .destination {
    text-align: right;
  }
  
  .origin p:first-child,
  .destination p:first-child {
    color: rgba(0, 0, 0, 0.7);
  }
  
  .origin span,
  .destination span {
    font-weight: 600;
    font-size: 18px;
  }
  
  .origin p:last-child,
  .destination p:last-child {
    color: red;
  }
  
  .tracking-stepper {
    margin-top: 20px;
    width: 100%;
    max-width: 100%;
  }
  
  @media (max-width: 600px) {
    .shipment-info {
      flex-direction: column;
      align-items: center;
    }
  
    .shipment-no,
    .latest-update {
      text-align: center;
    }
  }
  </style>