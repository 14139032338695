<template>
    <main class="orders_wrapper">
        <div class="page-head">
            <div class="container">
                <router-link to="/">{{ lang == 'en' ? 'Home' : 'الرئيسية' }}</router-link> <i :class="lang == 'en' ? 'fa-solid fa-chevron-right' : 'fa-solid fa-chevron-left'"></i> {{ lang == 'en' ? 'Account' : 'الحساب' }} <i :class="lang == 'en' ? 'fa-solid fa-chevron-right' : 'fa-solid fa-chevron-left'"></i>  {{ lang == 'en' ? 'Rewards' : 'عمليات الشراء' }}
            </div>
        </div>
        <div class="container">
            <div class="order-top-container">
                <h1 class="title">{{ lang == 'en' ? 'Points Summary' : 'رصيد النقاط' }}</h1>
            </div>
            <div class="reward-main-info reward-balance-info">
                <div>
                    <span>{{ lang == 'en' ? 'Available Balance' : 'النقاط المتاحة' }}</span>: <span>{{ main_info.available_balance }}</span>
                </div>
                <div>
                    <span>{{ lang == 'en' ? 'Pending Balance' : 'النقاط المعلقة' }}</span>: <span>{{ main_info.pending_balance }}</span>
                </div>
                <div>
                    <span>{{ lang == 'en' ? 'Total' : 'الاجمالي' }}</span>: <span>{{ main_info.total_balance }}</span>
                </div>
            </div>
            <div class="reward-main-info listing-info-header">
                <div>{{ lang == 'en' ? 'Transactions' : 'الطلب' }} </div>
                <div class="ml-auto order-sort-select">
                    {{ lang == 'en' ? 'Sort By Status:' : 'ترتيب حسب:' }}
                    <select name="per_pag" id="per_page" v-model="sort_by_status">
                        <option value="all" selected>{{ lang == 'en' ? 'All points' : 'جميع النقاط' }}</option>
                        <option value="available">{{ lang == 'en' ? 'Available' : 'متاح' }}</option>
                        <option value="pending">{{ lang == 'en' ? 'Pending' : 'معلق' }}</option>
                        <option value="redeemed">{{ lang == 'en' ? 'Redeemed' : 'مستخدم' }}</option>
                    </select>
                </div>
            </div>
            <div class="table_wrapper" v-if="rewards && rewards.length > 0">
                <table>
                    <thead>
                        <tr>
                            <td>{{ lang == 'en' ? 'order' : 'الطلب' }} #</td>
                            <td>{{ lang == 'en' ? 'Points' : 'وسيلة الدفع' }}</td>
                            <td>{{ lang == 'en' ? 'Maturity Date' : 'المجموع' }}</td>
                            <td>{{ lang == 'en' ? 'Expiry Date' : 'التاريخ' }}</td>
                            <td>{{ lang == 'en' ? 'Status' : 'الحالة' }}</td>
                            <td>{{ lang == 'en' ? 'Redeem Date' : 'تفاصيل' }}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="reward in rewards" :key="reward.id" >
                            <td>{{reward?.order?.name }}</td>
                            <td>{{reward.points }}</td>
                            <td>{{reward.maturity_date }}</td>
                            <td>{{reward.expiry_date }}</td>
                            <td><span :class="reward.status == 'Available' ? 'reward-available' : reward.status == 'Redeemed' ? 'reward-redeemed' : '' ">{{reward.status }}</span></td>
                            <td>{{reward.redeem_date }}</td>
                            <!-- <td><button @click="this.$router.push(`/order/${order.id}`)">{{ lang == 'en' ? 'View Order' : 'عرض الطلب' }}</button></td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
            <h1 v-if="!rewards || rewards.length == 0"  style="width:100%;margin: 5rem 0px; text-align: center; color: rgb(113, 113, 113);">{{ lang == 'en' ? 'No rewards yet' : 'لا يوجد عمليات سابقة' }}</h1>
            <div v-if="last_page > 1 && rewards && rewards.length > 0" class="pagination" >
                <div v-for="page_num in last_page" :key="page_num" >
                    <label :for="`page_num_${page_num}`" :class="page_num == page ? 'active' : ''">{{ page_num }}</label>
                    <input type="radio" name="page_num" :id="`page_num_${page_num}`" v-model="page" :value="page_num" @change="getRewards()">
                </div>
            </div>
        </div>
    </main>
</template>

<script>
const API_URL = process.env.VUE_APP_API_URL
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

import axios from 'axios';

export default {
    name: 'RewardsView',
    data() {
        return {
            rewards: [],
            lang: 'en',
            sort_by_status: 'all',
            is_data_reading: false,
            per_page: 20,
            page: 1,
            total: 0,
            last_page: 0,
            main_info: {
                available_balance: 0,
                pending_balance: 0,
                total_balance: 0
            }
        }
    },
    watch:{
        sort_by_status(){
            this.page= 1;
            this.total= 0;
            this.last_page= 0;
            this.getRewards();
        }
    },
    methods: {
        async getRewards() {
            this.is_data_reading = true;
            $('.loader').fadeIn().css('display', 'flex')
            try {
                const response = await axios.get(`${API_URL}/users/points-summary?status=${this.sort_by_status}&per_page=${this.per_page}&page=${this.page}`,
                    {
                        headers: {
                            "AUTHORIZATION": 'Bearer ' + sessionStorage.getItem('user_token'),
                            "lang": this.lang
                        },
                    }
                );
                this.is_data_reading = false
                if (response.data.status === true) {
                    $('.loader').fadeOut()
                    this.rewards = response.data.data.transactions
                    this.main_info.available_balance = response.data.data.available_balance
                    this.main_info.total_balance = response.data.data.total_balance
                    this.main_info.pending_balance = response.data.data.pending_balance

                    this.total = response.data.data.total
                    this.last_page = response.data.data.last_page
                } else {
                    $('.loader').fadeOut()
                    document.getElementById('errors').innerHTML = ''
                    $.each(response.data.errors, function (key, value) {
                        let error = document.createElement('div')
                        error.classList = 'error'
                        error.innerHTML = value
                        document.getElementById('errors').append(error)
                    });
                    $('#errors').fadeIn('slow')

                    setTimeout(() => {
                        $('input').css('outline', 'none')
                        $('#errors').fadeOut('slow')
                    }, 3500);
                }

            } catch (error) {
                this.is_data_reading = false
                document.getElementById('errors').innerHTML = ''
                let err = document.createElement('div')
                err.classList = 'error'
                err.innerHTML = 'server error try again later'
                document.getElementById('errors').append(err)
                $('#errors').fadeIn('slow')
                $('.loader').fadeOut()

                setTimeout(() => {
                    $('#errors').fadeOut('slow')
                }, 3500);

                console.error(error);
            }
        },
        setLangCookies() {
            let langCheck = document.cookie.indexOf('lang')

            this.is_cookies = langCheck >= 0 ? true : false

            function getCookie(cname) {
                let name = cname + "=";
                let decodedCookie = decodeURIComponent(document.cookie);
                let ca = decodedCookie.split(';');
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            } // to get an cookie by name ##############################

            if (langCheck !== -1) {
                this.lang = getCookie('lang') // check lang cookie exist ##############################
            }

            if (sessionStorage.getItem("lang"))
                this.lang = sessionStorage.getItem("lang") // check lang session exist ##############################

            sessionStorage.setItem("lang", this.lang); // set lang session ##############################

            let searchParams = new URLSearchParams(window.location.search)
            if (searchParams.has('lang')) {
                this.lang = searchParams.get('lang')
                document.body.classList.add(searchParams.get('lang')) // add lang class ##############################
            } else {
                document.body.classList.add(this.lang) // add lang class ##############################
            }

        },
        getHomeData() {
            this.setLangCookies()
            this.getRewards()
        },
    },
    created() {
        this.getHomeData()
    },
    mounted() {
    },
}
</script>

<style scoped>@import './../assets/css/home.css';</style>
<style scoped>
.order-top-container{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}
@media (max-width: 800px) {
    .order-top-container {
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
    }
    .title{
        margin-left: unset !important;
    }
    .order-sort-select{
        margin-left: unset !important;
    }
}
.reward-available{
    color: #4ad780 !important;
}
.reward-redeemed{
    color: red !important;
}
.reward-main-info {
    color: #717171;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    font-size: 18px;
}

.reward-balance-info {
    border: 1px solid #d5dfe4;
    padding: 14px;
    border-radius: 1rem 1rem 0px 0px;
}
.listing-info-header{
    margin-top: 36px;
}
</style>