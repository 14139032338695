<template>
    <main class="orders_wrapper">
        <div class="page-head">
            <div class="container">
                <router-link to="/">{{ lang == 'en' ? 'Home' : 'الرئيسية' }}</router-link> <i :class="lang == 'en' ? 'fa-solid fa-chevron-right' : 'fa-solid fa-chevron-left'"></i> {{ lang == 'en' ? 'Account' : 'الحساب' }} <i :class="lang == 'en' ? 'fa-solid fa-chevron-right' : 'fa-solid fa-chevron-left'"></i>  {{ lang == 'en' ? 'Wallet' : 'المحفظة النقدية' }}
            </div>
        </div>
        <div class="container">
            <div class="order-top-container">
                <h1 class="title">{{ lang == 'en' ? 'Cash Wallet' : 'المحفظة النقدية' }}</h1>
            </div>
            <div class="reward-main-info reward-balance-info">
                <div>
                    <span>{{ lang == 'en' ? 'Available Cash Balance' : 'الرصيد النقدى المتاح' }}</span>: <span>{{ main_info.available_balance }} {{ lang == 'en' ? 'EGP' : 'جنيه' }} </span>
                </div>
            </div>
            <div class="reward-main-info listing-info-header">
                <div>{{ lang == 'en' ? 'Wallet Transactions' : 'معاملات المحفظة' }} </div>
                <div class="ml-auto order-sort-select">
                    {{ lang == 'en' ? 'Sort By Status:' : 'ترتيب حسب:' }}
                    <select name="per_pag" id="per_page" v-model="sort_by_status">
                        <option value="" selected>{{ lang == 'en' ? 'All' : 'الكل' }}</option>
                        <option value="credit">{{ lang == 'en' ? 'Credit' : 'اضافة' }}</option>
                        <option value="debit">{{ lang == 'en' ? 'Debit' : 'خصم' }}</option>
                    </select>
                </div>
            </div>
            <div class="table_wrapper" v-if="transactions && transactions.length > 0">
                <table>
                    <thead>
                        <tr>
                            <td>{{ lang == 'en' ? 'ID' : 'مسلسل' }}</td>
                            <td>{{ lang == 'en' ? 'Operation' : 'نوع العملية ' }}</td>
                            <td>{{ lang == 'en' ? 'Amount' : 'المبلغ' }}</td>
                            <td>{{ lang == 'en' ? 'Description' : 'الوصف' }}</td>
                            <td>{{ lang == 'en' ? 'Reference' : 'الرقم المرجعى' }}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="transaction in transactions" :key="transaction.id" >
                            <td>{{transaction?.id }}</td>
                            <td>{{transaction?.operation }}</td>
                            <td>{{transaction.amount }}</td>
                            <td>{{transaction.description }}</td>
                            <td>{{transaction.reference }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <h1 v-if="!transactions || transactions.length == 0"  style="width:100%;margin: 5rem 0px; text-align: center; color: rgb(113, 113, 113);">{{ lang == 'en' ? 'No transactions yet' : 'لا يوجد عمليات سابقة' }}</h1>
            <div v-if="last_page > 1 && transactions && transactions.length > 0" class="pagination" >
                <div v-for="page_num in last_page" :key="page_num" >
                    <label :for="`page_num_${page_num}`" :class="page_num == page ? 'active' : ''">{{ page_num }}</label>
                    <input type="radio" name="page_num" :id="`page_num_${page_num}`" v-model="page" :value="page_num" @change="getWalletTransactions()">
                </div>
            </div>
        </div>
    </main>
</template>

<script>
const API_URL = process.env.VUE_APP_API_URL
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

import axios from 'axios';

export default {
    name: 'WalletsView',
    data() {
        return {
            transactions: [],
            lang: 'en',
            sort_by_status: '',
            is_data_reading: false,
            per_page: 20,
            page: 1,
            total: 0,
            last_page: 0,
            main_info: {
                available_balance: 0
            },
        }
    },
    watch:{
        sort_by_status(){
            // this.page= 1;
            this.total= 0;
            this.last_page= 0;
            this.getWalletTransactions();
        }
    },
    methods: {
        async getWalletTransactions() {
            this.is_data_reading = true;
            $('.loader').fadeIn().css('display', 'flex')
            try {
                const response = await axios.get(`${API_URL}/users/wallets/transactions?page=${this.page}&per_page=10&type=${this.sort_by_status}`,
                    {
                        headers: {
                            "AUTHORIZATION": 'Bearer ' + sessionStorage.getItem('user_token'),
                            "lang": this.lang
                        },
                    }
                );
                this.is_data_reading = false
                if (response.data.status === true) {
                    $('.loader').fadeOut()
                    let data = response.data.data
                    this.main_info.available_balance = data.wallet.balance
                    this.transactions = data.transactions.transactions
                    this.last_page = data?.transactions?.last_page
                } else {
                    $('.loader').fadeOut()
                    document.getElementById('errors').innerHTML = ''
                    $.each(response.data.errors, function (key, value) {
                        let error = document.createElement('div')
                        error.classList = 'error'
                        error.innerHTML = value
                        document.getElementById('errors').append(error)
                    });
                    $('#errors').fadeIn('slow')

                    setTimeout(() => {
                        $('input').css('outline', 'none')
                        $('#errors').fadeOut('slow')
                    }, 3500);
                }

            } catch (error) {
                this.is_data_reading = false
                document.getElementById('errors').innerHTML = ''
                let err = document.createElement('div')
                err.classList = 'error'
                err.innerHTML = 'server error try again later'
                document.getElementById('errors').append(err)
                $('#errors').fadeIn('slow')
                $('.loader').fadeOut()

                setTimeout(() => {
                    $('#errors').fadeOut('slow')
                }, 3500);

                console.error(error);
            }
        },
        setLangCookies() {
            let langCheck = document.cookie.indexOf('lang')

            this.is_cookies = langCheck >= 0 ? true : false

            function getCookie(cname) {
                let name = cname + "=";
                let decodedCookie = decodeURIComponent(document.cookie);
                let ca = decodedCookie.split(';');
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            } // to get an cookie by name ##############################

            if (langCheck !== -1) {
                this.lang = getCookie('lang') // check lang cookie exist ##############################
            }

            if (sessionStorage.getItem("lang"))
                this.lang = sessionStorage.getItem("lang") // check lang session exist ##############################

            sessionStorage.setItem("lang", this.lang); // set lang session ##############################

            let searchParams = new URLSearchParams(window.location.search)
            if (searchParams.has('lang')) {
                this.lang = searchParams.get('lang')
                document.body.classList.add(searchParams.get('lang')) // add lang class ##############################
            } else {
                document.body.classList.add(this.lang) // add lang class ##############################
            }

        },
        getHomeData() {
            this.setLangCookies()
            this.getWalletTransactions();
        },
    },
    created() {
        this.getHomeData()
    },
    mounted() {
    },
}
</script>

<style scoped>@import './../assets/css/home.css';</style>
<style scoped>
.order-top-container{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}
@media (max-width: 800px) {
    .order-top-container {
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
    }
    .title{
        margin-left: unset !important;
    }
    .order-sort-select{
        margin-left: unset !important;
    }
}
.reward-available{
    color: #4ad780 !important;
}
.reward-redeemed{
    color: red !important;
}
.reward-main-info {
    color: #717171;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    font-size: 18px;
}

.reward-balance-info {
    border: 1px solid #d5dfe4;
    padding: 14px;
    border-radius: 1rem 1rem 0px 0px;
}
.listing-info-header{
    margin-top: 36px;
}
</style>