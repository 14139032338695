<template>
  <header class="main">
    <div class="head">
      <div class="container">
        <div class="left">
          <p>
            {{ page_data.welcome_header }}
            <span dir="ltr">{{ page_data.egs }}</span>
          </p>
          <ul>
            <router-link to="/about-us">
              <li>{{ page_data.about_us }}</li>
            </router-link>
            <!-- <router-link to="/careers">
                            <li>{{page_data.careers}}</li>
                        </router-link> -->
            <router-link to="/contact-us">
              <li>{{ page_data.contact }}</li>
            </router-link>
            <a href="/faq">
              <li>{{ page_data.faq }}</li>
            </a>
          </ul>
        </div>
        <div class="right">
          <ul>
            <a href="tel:01145636999">
              <img
                src="./../assets/imgs/phone-solid-white.svg"
                alt="phone icon"
              />
              <li>{{ page_data.need_help }}: 01145636999</li>
            </a>
            <a href="mailto:support@egyptgamestore.com">
              <img
                src="./../assets/imgs/envelope-regular-white.svg"
                alt="email icon"
              />
              <li>support@egyptgamestore.com</li>
            </a>
            <router-link to="">
              <img
                src="./../assets/imgs/globe-solid-white.svg"
                alt="email icon"
              />
              <li>
                <select v-model="lang" @change="changeLang()">
                  <option style="color: #000" value="en">English</option>
                  <option style="color: #000" value="ar">العربية</option>
                </select>
              </li>
            </router-link>
          </ul>
        </div>
      </div>
    </div>
    <div class="body">
      <div class="container">
        <div class="left">
          <router-link to="/">
            <img src="./../assets/imgs/logo.png" alt="logo" />
          </router-link>
          <nav>
            <a href="" class="close"><i class="fa fa-close"></i></a>
            <router-link to="/" class="active home_link"
              >{{ page_data.home }}<span></span
            ></router-link>
            <a href="https://w2eg.com/">World2Egypt<span></span></a>
            <router-link to="/category/physical-store" class="physical-store"
              >{{ page_data.phy_store }}
              <v-menu
                :open-on-focus="false"
                activator="parent"
                open-on-hover
                :location="lang == 'ar' ? 'start' : 'end'"
              >
                <v-list>
                  <v-list-item
                    v-for="category in categories"
                    :key="category.id"
                    link
                    @mouseenter="fetchSubCategoriesMethod(category)"
                  >
                    <router-link
                      :to="
                        '/physical-store/' +
                        category.name.toLowerCase().replace(/\s+/g, '-') +
                        '/' +
                        category.id
                      "
                      class="digital-store-ancher"
                    >
                      <v-list-item-title
                        :class="lang == 'ar' ? 'text-right' : ''"
                      >
                        {{ category.name }}</v-list-item-title
                      ></router-link
                    >
                    <template
                      v-if="category.has_children && lang == 'en'"
                      v-slot:append
                    >
                      <v-progress-circular
                        v-if="sub_cat_loading[category.id]"
                        color="#ff3100"
                        width="3"
                        size="20"
                        indeterminate
                      ></v-progress-circular>
                      <v-icon
                        v-else
                        icon="fa-solid fa-chevron-right"
                        size="12"
                      ></v-icon>
                    </template>
                    <template
                      v-if="category.has_children && lang == 'ar'"
                      v-slot:prepend
                    >
                      <v-progress-circular
                        v-if="sub_cat_loading[category.id]"
                        color="#ff3100"
                        width="3"
                        size="20"
                        indeterminate
                      ></v-progress-circular>
                      <v-icon
                        v-else
                        icon="fa-solid fa-chevron-left"
                        size="12"
                      ></v-icon>
                    </template>
                    <v-menu
                      :open-on-focus="false"
                      activator="parent"
                      open-on-hover
                      submenu
                      :location="lang == 'ar' ? 'start' : 'end'"
                    >
                      <v-list>
                        <v-list-item
                          v-for="subCategory in subCategoriesMap[category.id]"
                          :key="subCategory.id"
                          link
                        >
                          <router-link
                            :to="
                              '/physical-store/' +
                              subCategory.name
                                .toLowerCase()
                                .replace(/\s+/g, '-') +
                              '/' +
                              subCategory.id +
                              '?children=' +
                              subCategory.has_children
                            "
                            class="digital-store-ancher"
                          >
                            <v-list-item-title>
                              {{ subCategory.name }}</v-list-item-title
                            >
                          </router-link>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item>
                </v-list>
              </v-menu>
              <span></span>
            </router-link>
            <router-link to="/category/digital-store" class="digital-store"
              >{{ page_data.card_store }}
              <v-menu
                :open-on-focus="false"
                activator="parent"
                open-on-hover
                max-height="500"
                :location="lang == 'ar' ? 'start' : 'end'"
              >
                <v-list>
                  <v-list-item
                    v-for="digCategory in digital_store_categories"
                    :key="digCategory.id"
                    link
                    @mouseenter="fetchDigitalSubCategoriesMethod(digCategory)"
                  >
                    <router-link
                      :to="
                        '/physical-store/' +
                        digCategory.name.toLowerCase().replace(/\s+/g, '-') +
                        '/' +
                        digCategory.id
                      "
                      class="digital-store-ancher"
                    >
                      <v-list-item-title
                        :class="lang == 'ar' ? 'text-right' : ''"
                      >
                        {{ digCategory.name }}</v-list-item-title
                      >
                    </router-link>
                    <template
                      v-if="digCategory.has_children && lang == 'en'"
                      v-slot:append
                    >
                      <v-progress-circular
                        v-if="sub_digital_store_cat_loading[digCategory.id]"
                        color="#ff3100"
                        width="3"
                        size="20"
                        indeterminate
                      ></v-progress-circular>
                      <v-icon
                        v-else
                        icon="fa-solid fa-chevron-right"
                        size="12"
                      ></v-icon>
                    </template>
                    <template
                      v-if="digCategory.has_children && lang == 'ar'"
                      v-slot:prepend
                    >
                      <v-progress-circular
                        v-if="sub_digital_store_cat_loading[digCategory.id]"
                        color="#ff3100"
                        width="3"
                        size="20"
                        indeterminate
                      ></v-progress-circular>
                      <v-icon
                        v-else
                        icon="fa-solid fa-chevron-left"
                        size="12"
                      ></v-icon>
                    </template>
                    <v-menu
                      :open-on-focus="false"
                      activator="parent"
                      open-on-hover
                      submenu
                      :location="lang == 'ar' ? 'start' : 'end'"
                    >
                      <v-list>
                        <v-list-item
                          v-for="sub_item in sub_digital_store_CategoriesMap[
                            digCategory.id
                          ]"
                          :key="sub_item.id"
                          link
                          @mouseenter="fetchCountriesMethod(sub_item)"
                        >
                          <router-link
                            :to="
                              '/digital-store/sub_category/' +
                              sub_item.name.toLowerCase().replace(/\s+/g, '-') +
                              '/' +
                              sub_item.id
                            "
                            class="digital-store-ancher"
                          >
                            <v-list-item-title
                              :class="lang == 'ar' ? 'text-right' : ''"
                            >
                              {{ sub_item.name }}</v-list-item-title
                            >
                          </router-link>

                          <template
                            v-if="sub_item.id && lang == 'en'"
                            v-slot:append
                          >
                            <v-progress-circular
                              v-if="country_loading[sub_item.id]"
                              color="#ff3100"
                              width="3"
                              size="20"
                              indeterminate
                            ></v-progress-circular>
                            <v-icon
                              v-else
                              icon="fa-solid fa-chevron-right"
                              size="12"
                            ></v-icon>
                          </template>
                          <template
                            v-if="sub_item.id && lang == 'ar'"
                            v-slot:prepend
                          >
                            <v-progress-circular
                              v-if="country_loading[sub_item.id]"
                              color="#ff3100"
                              width="3"
                              size="20"
                              indeterminate
                            ></v-progress-circular>
                            <v-icon
                              v-else
                              icon="fa-solid fa-chevron-left"
                              size="12"
                            ></v-icon>
                          </template>
                          <v-menu
                            :open-on-focus="false"
                            activator="parent"
                            open-on-hover
                            submenu
                            :location="lang == 'ar' ? 'start' : 'end'"
                          >
                            <v-list>
                              <v-list-item
                                v-for="country in countryMap[sub_item.id]"
                                :key="country.id"
                                link
                              >
                                <router-link
                                  :to="
                                    '/digital-store/' +
                                    country.name
                                      .toLowerCase()
                                      .replace(/\s+/g, '-')
                                      .replace(/\//g, ',') +
                                    '/' +
                                    country.id +
                                    '/' +
                                    sub_item.name
                                      .toLowerCase()
                                      .replace(/\s+/g, '-') +
                                    '/' +
                                    sub_item.id
                                  "
                                  class="digital-store-ancher"
                                >
                                  <v-list-item-title>
                                    {{ country.name }}</v-list-item-title
                                  >
                                </router-link>

                                <template
                                  v-if="country.has_children"
                                  v-slot:append
                                >
                                  <v-icon
                                    icon="fa-solid fa-chevron-right"
                                    size="12"
                                  ></v-icon>
                                </template>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item>
                </v-list>
              </v-menu>
              <span></span
            ></router-link>
            <router-link class="deals_link" to="/deals"
              >{{ page_data.deals }}<span></span
            ></router-link>
            <a
              href=""
              class="lang_mobile"
              @click.prevent="this.showLangMore = !this.showLangMore"
            >
              <i class="fa fa-globe"></i>
              <span>
                {{ lang == "en" ? "English" : "العربية" }}
              </span>
              <i class="fa-solid fa-chevron-down"></i>
              <span
                style="color: #0b5177"
                v-if="showLangMore"
                class="more_lang"
              >
                <span @click="(lang = 'en'), changeLang()">English</span>
                <span @click="(lang = 'ar'), changeLang()">العربية</span>
              </span>
            </a>

            <div class="mobile-more">
              <ul>
                <a href="tel:01145636999">
                  <i class="fa fa-phone"></i>
                  {{ lang == "en" ? "Call Us" : "اتصل بنا" }}
                </a>
                <a href="mailto:support@egyptgamestore.com">
                  <i class="fa fa-envelope"></i>
                  {{ lang == "en" ? "Email Us" : "راسلنا" }}
                </a>
              </ul>
              <ul>
                <router-link to="/about-us">
                  <li>{{ page_data.about_us }}</li>
                </router-link>
                <router-link to="/careers">
                  <li>{{ page_data.careers }}</li>
                </router-link>
                <router-link to="/contact-us">
                  <li>{{ page_data.contact }}</li>
                </router-link>
                <a href="/faq">
                  <li>{{ page_data.faq }}</li>
                </a>
              </ul>
            </div>
          </nav>
          <div class="hide"></div>
        </div>
        <div class="right">
          <div class="controls">
            <router-link to="" class="account_btn">
              <i class="fa-regular fa-user"></i
              ><span>{{ page_data.account }}</span>
            </router-link>
            <ul v-if="user == null">
              <router-link to="/login"
                ><i class="fa-solid fa-chevron-right"></i>
                {{ page_data.login }}</router-link
              >
              <!-- <router-link to="/register"><i class="fa-solid fa-chevron-right"></i> {{page_data.register}}</router-link> -->
            </ul>
            <ul v-if="user != null">
              <router-link to="/edit-profile"
                ><i class="fa-solid fa-chevron-right"></i>
                {{ page_data.my_profile }}</router-link
              >
              <router-link to="/my-orders"
                ><i class="fa-solid fa-chevron-right"></i>
                {{ page_data.my_orders }}</router-link
              >
              <router-link to="/wallet"
                ><i class="fa-solid fa-chevron-right"></i>
                {{ page_data.wallet }}</router-link
              >
              <router-link to="/rewards"
                ><i class="fa-solid fa-chevron-right"></i>
                {{ page_data.rewards }}</router-link
              >
              <router-link to="/log-out" @click.prevent="logout()"
                ><i class="fa-solid fa-chevron-right"></i>
                {{ page_data.logout }}</router-link
              >
            </ul>
            <router-link to="/my-wishlist" v-if="user != null"
              ><i class="fa-regular fa-heart"></i
              ><span>{{ page_data.wishlist }}</span></router-link
            >
            <router-link to="/my-cart">
              <div class="cart_icon_wrapper">
                <i class="fa-solid fa-cart-shopping"></i
                ><span class="point" v-if="cart && cart.length">{{ cart.length }}</span>
                <span v-else-if="localCart && localCart.length" class="point"> {{ localCart.length }}</span>
              </div>
              <span>{{ page_data.cart }}</span>
            </router-link>
            <router-link to="/compare"
              ><i class="fa-solid fa-arrow-right-arrow-left"></i
              ><span>{{ page_data.compare }}</span></router-link
            >
            <router-link
              to=""
              class="search-icon"
              @click.prevent="showSearchPopUp = true"
              ><i class="fa-solid fa-search"></i
              ><span>{{ page_data.search }}</span></router-link
            >
            <router-link to="" class="more"
              ><i class="fa-solid fa-bars"></i
            ></router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="hide-content" v-if="showSearchPopUp"></div>
    <div class="pop-up search-pop-up" v-if="showSearchPopUp">
      <div class="input-search">
        <input
          type="text"
          name="search"
          id="search"
          :placeholder="page_data.search_text"
          v-model="search"
          @keyup="getSugesstions()"
          @keyup.enter="goToSearch"
          @focus="showSuggesstion = true"
          @blur="showSuggesstion = false"
        />
        <i class="fa fa-search" style="cursor: pointer" @click="goToSearch"></i>
        <div class="suggestions suggestions2" v-if="results && results.length">
          <router-link
            :to="
              item.product_type == 1
                ? `/product/${item.id}/${replaceSpaces(item.name)}`
                : `/card/${item.id}/${replaceSpaces(item.name)}`
            "
            v-for="item in results.slice(0, 5)"
            :key="item.id"
            @click="showSearchPopUp = false"
            >{{ item.name }}</router-link
          >
          <a
            href=""
            style="text-align: center !important; font-weight: 600 !important"
            @click.prevent="goToSearch"
            v-if="results && results.length > 5"
            >{{ page_data.view_all }}</a
          >
        </div>
      </div>
      <button
        @click="
          showSearchPopUp = false;
          this.search = '';
        "
      >
        {{ page_data.cancel }}
      </button>
    </div>
  </header>
</template>

<script>
const API_URL = process.env.VUE_APP_API_URL
global.jQuery = require("jquery");
var $ = global.jQuery;
window.$ = $;

import axios from "axios";
import { destroyAllCookies } from "./../assets/js/destroy-cookies";
import { mapState, mapActions } from "vuex";
// import { computed } from "vue";
// import { useStore } from "vuex";
export default {
  name: "MainHeader",
  // setup() {
  //   const store = useStore();
  //   const cartLength = computed(() => store.state.cartLength);
  //   return { cartLength };
  // },
  data() {
    return {
      showSuggesstion: false,
      user: null,
      search: "",
      showSearchPopUp: false,
      cart: null,
      quantities: {},
      total: 0,
      products_cart: null,
      cards_cart: null,
      results: null,
      products: null,
      cards: null,
      lang: "en",
      page_data: null,
      showLangMore: false,
      menu: false,
    };
  },
  computed: {
    ...mapState({
      categories: (state) => state.categories,
      cat_loading: (state) => state.cat_loading,
      subCategoriesMap: (state) => state.subCategoriesMap,
      sub_cat_loading: (state) => state.sub_cat_loading,
      digital_store_categories: (state) => state.digital_store_categories,
      cat_digital_store_loading: (state) => state.cat_digital_store_loading,
      sub_digital_store_CategoriesMap: (state) =>
        state.sub_digital_store_CategoriesMap,
      sub_digital_store_cat_loading: (state) =>
        state.sub_digital_store_cat_loading,
      countryMap: (state) => state.countryMap,
      country_loading: (state) => state.country_loading,
      error: (state) => state.error,
    }),
    localCart(){
      return JSON.parse(sessionStorage.getItem('cartItems'))
    }
  },
  watch: {
    lang(newLang) {
      this.updateLang(newLang);
      this.fetchAllCategoriesAndSubCategories();
      this.fetchAlldigitalCategoriesAndSubCategories();
    },
    // subCategoriesMap: {
    //   handler(newVal, oldVal) {
    //     if (newVal !== oldVal) {
    //       this.$forceUpdate(); // Force the component to re-render when subcategories are updated
    //     }
    //   },
    //   deep: true, // Watch for changes in the nested object
    // },
  },
  methods: {
    ...mapActions([
      "updateLang",
      "fetchAllCategoriesAndSubCategories",
      "fetchAlldigitalCategoriesAndSubCategories",
      "fetchSubCategories",
      "fetchDigitalSubCategories",
      "fetchCountries",
    ]),
    fetchSubCategoriesMethod(category) {
      if (
        !this.subCategoriesMap[category.id] &&
        !this.sub_cat_loading[category.id] &&
        category.has_children
      ) {
        this.fetchSubCategories(category.id);
      }
    },
    fetchDigitalSubCategoriesMethod(category) {
      if (
        !this.sub_digital_store_CategoriesMap[category.id] &&
        !this.sub_digital_store_cat_loading[category.id] &&
        category.has_children
      ) {
        this.fetchDigitalSubCategories(category.id);
      }
    },
    fetchCountriesMethod(category) {
      if (!this.countryMap[category.id] && !this.country_loading[category.id]) {
        this.fetchCountries(category.id);
      }
    },
    changeLang() {
      document.body.classList.add(this.lang);
      document.body.classList.remove(this.lang == "ar" ? "en" : "ar");

      document.cookie =
        "lang=" + this.lang + "; max-age=" + 60 * 60 * 24 * 30 + "; path=/";

      sessionStorage.setItem("lang", this.lang);
      var searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has("lang")) {
        window.location.href = "/";
      }

      window.location.reload();
    },
    setLangCookies() {
      let langCheck = document.cookie.indexOf("lang");

      this.is_cookies = langCheck >= 0 ? true : false;

      function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(";");
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == " ") {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      } // to get an cookie by name ##############################

      if (langCheck !== -1) {
        this.lang = getCookie("lang"); // check lang cookie exist ##############################
      }

      if (sessionStorage.getItem("lang"))
        this.lang = sessionStorage.getItem("lang"); // check lang session exist ##############################

      sessionStorage.setItem("lang", this.lang); // set lang session ##############################

      let searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has("lang")) {
        this.lang = searchParams.get("lang");
        document.body.classList.add(searchParams.get("lang")); // add lang class ##############################
      } else {
        document.body.classList.add(this.lang); // add lang class ##############################
      }
    },
    async logout() {
      $(".loader").fadeIn().css("display", "flex");
      try {
        const response = await axios.post(
          `${API_URL}/users/web/logout`,
          {},
          {
            headers: {
              AUTHORIZATION: "Bearer " + sessionStorage.getItem("user_token"),
            },
          }
        );
        if (response.data.status === true) {
          destroyAllCookies();
          sessionStorage.removeItem("user");
          sessionStorage.removeItem("user_token");
          document.getElementById("errors").innerHTML = "";
          let error = document.createElement("div");
          error.classList = "success";
          error.innerHTML = response.data.message;
          document.getElementById("errors").append(error);
          $("#errors").fadeIn("slow");
          setTimeout(() => {
            $("#errors").fadeOut("slow");
            $(".loader").fadeOut();
            window.location.href = "/";
          }, 1000);
        } else {
          $(".loader").fadeOut();
          document.getElementById("errors").innerHTML = "";
          $.each(response.data.errors, function (key, value) {
            let error = document.createElement("div");
            error.classList = "error";
            error.innerHTML = value;
            document.getElementById("errors").append(error);
          });
          $("#errors").fadeIn("slow");

          setTimeout(() => {
            $("input").css("outline", "none");
            $("#errors").fadeOut("slow");
          }, 3500);
        }
      } catch (error) {
        document.getElementById("errors").innerHTML = "";
        let err = document.createElement("div");
        err.classList = "error";
        err.innerHTML = "server error try again later";
        document.getElementById("errors").append(err);
        $("#errors").fadeIn("slow");
        $(".loader").fadeOut();

        setTimeout(() => {
          $("#errors").fadeOut("slow");
        }, 3500);

        console.error(error);
      }
    },
    goToSearch() {
      window.location.href = "/search/" + this.search.replace(/\s+/g, "-");
    },
    async getSugesstions() {
      try {
        const response = await axios.get(
          `${API_URL}/products/search?search=${this.search}`,
          {
            headers: {
              AUTHORIZATION: "Bearer " + sessionStorage.getItem("user_token"),
            },
          }
        );
        if (response.data.status === true) {
          this.products = response.data.data.products.products;
          for (let i = 0; i < this.products.length; i++) {
            this.products[i].product_type = 1;
            this.quantities[`product_${this.products[i].id}`] =
              this.products[i].qty;
          }
          this.cards = response.data.data.cards.cards;
          for (let i = 0; i < this.cards.length; i++) {
            this.cards[i].product_type = 2;
            this.quantities[`card_${this.cards[i].id}`] = this.cards[i].qty;
          }
          this.results = this.products.concat(this.cards);
        } else {
          this.results = null;
        }
      } catch (error) {
        document.getElementById("errors").innerHTML = "";
        let err = document.createElement("div");
        err.classList = "error";
        err.innerHTML = "server error try again later";
        document.getElementById("errors").append(err);
        $("#errors").fadeIn("slow");
        $(".loader").fadeOut();

        setTimeout(() => {
          $("#errors").fadeOut("slow");
        }, 3500);

        console.error(error);
      }
    },
    async getCart() {
      $(".loader").fadeIn().css("display", "flex");
      try {
        const response = await axios.get(
          `${API_URL}/users/cart`,
          {
            headers: {
              AUTHORIZATION: "Bearer " + sessionStorage.getItem("user_token"),
            },
          }
        );
        if (response.data.status === true) {
          $(".loader").fadeOut();
          this.showMsgPopUp = response.data.data.is_cart_updated;
          this.total = response.data.data.total;
          this.products_cart = response.data.data.products;

          for (let i = 0; i < this.products_cart.length; i++) {
            this.products_cart[i].product_type = 1;
            this.quantities[`product_${this.products_cart[i].id}`] =
              this.products_cart[i].qty;
          }
          this.cards_cart = response.data.data.cards;
          for (let i = 0; i < this.cards_cart.length; i++) {
            this.cards_cart[i].product_type = 2;
            this.quantities[`card_${this.cards_cart[i].id}`] =
              this.cards_cart[i].qty;
          }
          this.cart = this.products_cart.concat(this.cards_cart);
        } else {
          $(".loader").fadeOut();
        }
      } catch (error) {
        $(".loader").fadeOut();
        console.error(error);
      }
    },
    getHomeData() {
      this.setLangCookies();
      let data = require("../assets/api/header.json");
      this.page_data = this.lang == "ar" ? data.ar : data.en;
    },
  },
  mounted() {
    this.user = sessionStorage.getItem("user")
      ? sessionStorage.getItem("user")
      : null;
    $(window).on("resize", function () {
      if ($(this).width() > 855) {
        $("nav").css("display", "flex");
      }
      $(".left nav a span").each(function () {
        $(this).width($(this).parent().width() + 10);
      });
    });

    $(".left nav a span").each(function () {
      $(this).width($(this).parent().width() + 10);
    });
    $(document).on("click", ".account_btn", function (e) {
      e.preventDefault();
      if ($(".controls >ul").hasClass("animate__bounceIn")) {
        $(".controls >ul")
          .fadeOut()
          .removeClass("animate__animated animate__bounceIn");
      } else {
        $(".controls >ul")
          .attr("style", "display: block !important;")
          .addClass("animate__animated animate__bounceIn");
      }
    });
    $(document).on("click", ".controls >ul", function (e) {
      e.preventDefault();
      if ($(".controls >ul").hasClass("animate__bounceIn")) {
        $(".controls >ul")
          .fadeOut()
          .removeClass("animate__animated animate__bounceIn");
      } else {
        $(".controls >ul")
          .attr("style", "display: block !important;")
          .addClass("animate__animated animate__bounceIn");
      }
    });
    $(document).on("click", ".more .fa-bars", function (e) {
      e.preventDefault();
      $("nav")
        .attr("style", "display: flex !important;")
        .addClass("animate__animated animate__fadeInRight");
      $(".hide").fadeIn();
    });
    $(document).on("click", ".close, .hide", function (e) {
      e.preventDefault();
      $("nav, .hide").fadeOut();
    });
    $(document).on("click", "nav a:not(.lang_mobile)", function (e) {
      e.preventDefault();
      if ($(window).width() < 855) {
        $("nav, .hide").fadeOut();
      }
    });
    $(window).resize(function () {
      // Check if the window width is greater than 600 pixels
      $(".left nav a span").each(function () {
        $(this).width($(this).parent().width() + 10);
      });
    });
  },
  async created() {
    this.getCart();
    this.getHomeData();
    // await this.fetchAllCategoriesAndSubCategories();
    // await this.fetchAlldigitCategoriesAndSubCategories();
  },
};
</script>
<style>
.v-overlay.v-menu {
  z-index: 1000000000000000000 !important;
}
a.digital-store-ancher {
  text-decoration: none !important;
  color: #0b5177 !important;
}
.v-list-item-title {
  color: #0b5177 !important;
}
</style>
