<template>
    <div id="turnstile-container"></div>
  </template>
  
  <script>
  export default {
    name: "turnstile-container",
    props: {
      sitekey: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        widgetId: null,
      };
    },
    mounted() {
      this.loadTurnstile();
    },
    methods: {
      loadTurnstile() {
        // Check if Turnstile script is already loaded
        if (!window.turnstile) {
          const script = document.createElement("script");
          script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js";
          script.async = true;
          script.onload = this.initTurnstile;
          document.head.appendChild(script);
        } else {
          this.initTurnstile();
        }
      },
      initTurnstile() {
        this.widgetId = window.turnstile.render("#turnstile-container", {
          sitekey: this.sitekey,
          theme: "light", // Change to "dark" if needed
          callback: (token) => {
            this.$emit("verified", token);
          },
        });
      },
    },
  };
  </script>
  